import React, { useState, useEffect } from 'react';

import './App.css';
import { Input, Card, Form, Table, Button } from 'antd';
import { LeftOutlined, DownloadOutlined  } from '@ant-design/icons';

import pptxgen from "pptxgenjs";
import Filter from 'bad-words';
import { searchSong, getSongById } from 'genius-lyrics-api';
import SlideCarousel from "./Components/SlideCarousel";
import Wallpaper from './wallpaper2.jpg'
import Logo from './qwslogolarge.png'
const filter = new Filter();

const { Search } = Input;
const { Column } = Table;

function App() {


    const [ currentSong, setCurrentSong ] = useState()
    const [ currentSongLyrics, setCurrentSongLyrics ] = useState()

    const [searchResults, setSearchResults] = useState();
    const searchColumns = [
        {'title': 'Select', "dataIndex": 'id', key: 'id', render: (text) =>
                <Button size="small" type="primary" onClick={
                    () =>
                    getSongById(text, API_KEY).then(res => {
                        const thisSong = [ ...searchResults.filter(result => result.id === res.id) ][0]
                        setCurrentSongLyrics(res.lyrics);
                        setCurrentSong(thisSong)}
                        )
                    }
                >Select</Button>
        },
        {'title': 'Title', 'dataIndex': 'title', 'key': 'title'},
        {'title': 'Artist', 'dataIndex': 'artist', 'key': 'artist'},
    ]
    const sectionNameRegex = /\[.*\](?!\n\n)/g;
    const songSectionNames = currentSongLyrics? [ ...currentSongLyrics.matchAll(sectionNameRegex) ].map(i => i[0]) : 'No Song Data';
    const songSectionContent = currentSongLyrics? currentSongLyrics.split(sectionNameRegex) : 'No Song Data'
    const sectionRepeat = /\[.*\]\n\n/;
    let songInJson = {};
    const songObject = currentSongLyrics? songSectionNames.forEach((val, ind) => songSectionContent.forEach((_val, _ind) => {
        if (ind === _ind - 1) {
            songInJson[val] = _val.replace('\n', '');
        }
    })) : 'No Song Data'

    //if (currentSongLyrics) console.log(songInJson)//{songSectionContent.forEach(i => console.log(i))}

    const API_KEY = '3GYISFY1hMNn4LGnB8bQTc-tJd-2YroE1Is_VSj7ttBE84qF4a06mT6LxEEARXLW'
    const searchBarStyle = { width: '90%' };

    const createSlideShow = () => {
        let pptx = new pptxgen();

        const titleSlide = pptx.addSlide();
        titleSlide.background = { color: '202020'}
        const titleSlideTextOptions = {
            x: '5%',
            y: '5%',
            w: '90%',
            h: '90%',
            fontFace: 'helvetica',
            fontSize: 30,
//                isTextBox: true,
            align: 'center',
            bold: true,
//                autoFit: true,
            color: 'white'
        }

        titleSlide.addText(`${currentSong.title}\n${currentSong.artist}`, titleSlideTextOptions)


        Object.keys(songInJson).forEach(key => {
            const slide = pptx.addSlide();
            slide.background = { color: '202020' }

            const textOptions = {
                x: '5%',
                y: '5%',
                w: '90%',
                h: '90%',
                fontFace: 'helvetica',
                fontSize: 20,
//                isTextBox: true,
                align: 'center',
                bold: true,
//                autoFit: true,
                color: 'gray'
            }

            slide.addText(songInJson[key], textOptions)

            //songInJson[key].split(/\n(?=.)/).forEach((item, idx) => slide.addText(item, textOptions))
        })

        pptx.writeFile({fileName: "pptxgenjs-demo-react.pptx"});
    }

    const searchBySongName = songName => searchSong({apiKey: API_KEY, title: songName, artist: '', optimizeQuery: true})
        .then(results => setSearchResults(results.filter(j => !filter.isProfane(j.title)).map((i, ii) => {
                const title = i.title.split(' by')[0];
                const artist = i.title.split(' by')[1];
                return {...i, title: title, artist: artist}
        }
        )))
        .catch(err => console.error(err))

    const getLyrics = songAndArtist => getLyrics({apiKey: API_KEY, title: songAndArtist.title, artist: songAndArtist.artist, optimizeQuery: true})
        .then(res => {console.log(Object.values(res)); if (!filter.isProfane(Object.values(res).join(' '))) {setCurrentSongLyrics(res)} else {setCurrentSongLyrics('Profanity Detected.')} })

    useEffect(() => {
        console.log('Search Results: ', searchResults)
    }, [searchResults])

    useEffect(() => {
        console.log('Current Song Set To: ', currentSong)
    }, [currentSong])

    const SearchResultsTable = <Table className={"darkTable"} key={searchResults} size="small"
                                      dataSource={searchResults} columns={searchColumns}
                                      style={{margin: '1rem', width: '90%'}}/>

    const LyricsSlides = Object.keys(songInJson).map(key =>
            <div key={songInJson[key]} style={{width: '600px', border: 'thin solid #505050', borderRadius: "10px", height: '90%', padding: '1rem', margin: '.5rem', display: 'flex', alignItems: 'center', background: '#202020'}}>
                <Card style={{height: '100%', flex: 1, alignItems: 'center', display: 'grid', overflowY: 'auto', background: '#222222', border: 'none', padding: '.5rem'}}>
                    <p style={{fontWeight: 'bold', flex: 1, fontSize: 'large', fontFamily: 'Helvetica', color: 'lightgray', textSizeAdjust: 'auto'}}>
                        {songInJson[key].split(/\n(?=.)/).map(function(item, idx) {
                            return (<span key={idx} style={{flex: 1, width: "100%"}}>{item} <br/></span>)
                    })}
                    </p>
                </Card>
            </div>
        )

  return (
    <div className="App" style={{backgroundImage: `url('${Wallpaper}')`, margin: 'none', overflow: 'hidden', height: '100vh'}}>
        <div style={{ width: '90%', height: '90%', margin: '5%'}} className={"glass"}>
      <div style={{color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', height: "100%"}}>
          <img src={Logo} style={{filter: 'invert(1)', marginBottom: '2rem', marginTop: '2rem', width: "80%"}}/>

          {/* Main Search Bar - Hide when viewing slideshow*/}
          {!currentSong &&
          <Search
              placeholder="Redeemed by Big Daddy Weave"
              enterButton="Search"
              size="large"
              onSearch={searchBySongName}
              style={searchBarStyle}
              />
          }

          {/* Results Table, or... || CurrentSong Header with Action Buttons*/}
          {searchResults && currentSong?
              <>
                <Card className={'glass'} style={{...searchBarStyle, margin: '.5rem', borderRadius: '10px', color: 'gray', border: 'none'}}>
                    <h2><span style={{color: "white", fontWeight: 'lighter', fontFamily: 'helvetica', fontSize: 'xx-large'}}>{currentSong.title} by{currentSong.artist}</span></h2>
                </Card>
              <div>
                <Button onClick={() => setCurrentSong(undefined)} danger type="text" style={{marginRight: '.5rem'}}>
                    <LeftOutlined /> Back to Results</Button>
                <Button onClick={() => createSlideShow()} type="primary" >
                    <DownloadOutlined /> Download as Slideshow</Button>
              </div>
            </>
           : searchResults && SearchResultsTable}

          {/*slides*/}
          {currentSong &&

          <div style={{marginTop: '1rem', height: '400px', width: '90%', display: '-webkit-box', flexDirection: 'row', overflowX: 'scroll'}}>
              {LyricsSlides}
          </div>
          }
      </div>
        </div>
    </div>
  );
}

export default App;
